<template>
	<div class="pa-4">
		<div v-if="!noDataFound">
			<div v-if="isLoading" color="grey lighten-4" class="pa-3">
				<v-row>
					<v-col cols="6" v-for="i in 4" :key="i">
						<v-skeleton-loader
							class="mx-auto"
							width="100%"
							type="card"
						></v-skeleton-loader>
					</v-col>
				</v-row>
			</div>
			<div v-else class="d-flex flex-column">
				<div>
					<v-btn
						style="float: right"
						class="mt-4 align-self-end mr-3"
						color="primary"
						rounded
						@click="showMediaDrawer = true"
						>+ Add Media</v-btn
					>
				</div>
				<v-row v-if="medias && medias.length > 0" class="mx-0">
					<v-col cols="6" v-for="media in medias" :key="media.name">
						<v-card height="100%">
							<div v-if="media.googleUrl" class="container">
								<v-img
									style="height: 400px; width: 100%"
									:src="media.googleUrl"
								></v-img>
								<div class="delete">
									<v-tooltip bottom>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<v-icon
												v-bind="attrs"
												v-on="on"
												small
												fab
												color="red"
												class="mr-2 delete-btn"
												@click="
													showDeleteAlert = true
													mediaName = media.name
												"
												>mdi-delete</v-icon
											><v-dialog
												v-model="showDeleteAlert"
												max-width="600px"
												v-if="showDeleteAlert"
											>
												<v-card
													class="pa-6 mb-0 d-flex flex-column delete-modal"
												>
													<div
														class="d-flex flex-row align-center"
													>
														<span
															class="mdi mdi-alert-circle icon mr-2"
														></span>
														<h1 class="heading">
															Are you sure you
															want to delete?
														</h1>
													</div>
													<div
														class="d-flex flex-row justify-end"
													>
														<v-btn
															class="mt-4 mr-4"
															outlined
															color="#721c24"
															@click="
																;(deleteConfirmed = true),
																	deleteMedia()
															"
															>Delete</v-btn
														>
														<v-btn
															class="mt-4"
															outlined
															color="indigo"
															@click="
																;(showDeleteAlert = false),
																	(deleteConfirmed = false)
															"
															>Cancel</v-btn
														>
													</div>
												</v-card>
											</v-dialog>
										</template>
										<span class="tooltip-text"
											>Delete Item</span
										>
									</v-tooltip>
								</div>
								<div
									class="d-flex flex-row justify-space-between bottom"
								>
									<div class="ml-2">
										<span
											class="mdi mdi-eye"
											style="color: #fff"
										></span
										>{{ media.insights?.viewCount }}
									</div>
									<p class="mr-2">
										{{ dateFormat(media.createTime) }}
									</p>
								</div>
							</div>
						</v-card>
					</v-col>
				</v-row>
				<div v-if="showMediaDrawer">
					<CreateMediaDrawer
						@close-drawer="closeDrawer($event)"
					></CreateMediaDrawer>
				</div>
			</div>
			<div class="d-flex flex-row justify-center ma-2">
				<v-btn
					@click="getMedia(previousPageToken, true, false)"
					:disabled="!previousPageToken"
					:loading="btnLoading"
					class="mr-2"
				>
					<span class="mdi mdi-arrow-left-bold"></span
				></v-btn>
				<v-btn
					@click="getMedia(nextPageToken, false, true)"
					:disabled="!nextPageToken"
					:loading="btnLoading"
					><span class="mdi mdi-arrow-right-bold"></span
				></v-btn>
			</div>
		</div>
		<div v-else>
			<v-btn
				style="float: right"
				class="mt-4 align-self-end mr-3"
				color="primary"
				rounded
				@click="showMediaDrawer = true"
				>+ Add Media
			</v-btn>
			<NoData></NoData>
		</div>
	</div>
</template>

<script>
import CreateMediaDrawer from './CreateMediaDrawer'
import NoData from '../../../components/NoData'

export default {
	components: {
		CreateMediaDrawer,
		NoData,
	},
	data() {
		return {
			btnLoading: false,
			page: 1,
			repeat: false,
			pageTokens: [],
			nextPageToken: null,
			previousPageToken: null,
			showDeleteAlert: false,
			deleteConfirmed: false,
			showMediaDrawer: false,
			isLoading: false,
			medias: [],
			snackbar: false,
			mediaName: null,
			noDataFound: false,
		}
	},
	computed: {
		locationName() {
			return this.$cookies.get('gmb-selected-location')
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	created() {
		this.getMedia()
	},
	methods: {
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		closeDrawer() {
			this.showMediaDrawer = !this.showMediaDrawer
			this.getMedia()
		},
		getMedia(nextPageToken, previous, next) {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			this.btnLoading = true
			let payload = {
				name: this.locationName,
				email: this.locationEmail,
				pageToken: nextPageToken ? nextPageToken : null,
			}
			if (nextPageToken === 'last') payload.pageToken = null
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/media`,
				data: payload,
			})
				.then((response) => {
					this.isLoading = false
					this.$nextTick(function () {
						this.medias = response?.data?.data?.mediaItems
						this.medias
							? (this.noDataFound = false)
							: (this.noDataFound = true)
					})
					if (next) {
						response.data.data.nextPageToken
							? this.pageTokens.push(
									response.data.data.nextPageToken
							  )
							: null
						this.previousPageToken =
							this.pageTokens.length <= 1
								? null
								: this.pageTokens[this.pageTokens.length - 2]
					}
					this.nextPageToken = response.data.data.nextPageToken
						? response.data.data.nextPageToken
						: null
					if (previous && this.previousPageToken) {
						this.pageTokens.splice(this.pageTokens.length - 1, 1)
					}
					this.previousPageToken =
						this.pageTokens.length === 0
							? null
							: this.pageTokens[this.pageTokens.length - 2]
					if (this.repeat) {
						this.previousPageToken =
							this.pageTokens.length === 1
								? 'last'
								: this.pageTokens[this.pageTokens.length - 2]
					}
					this.repeat = true
					this.btnLoading = false
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err?.response?.data?.message,
						color: 'error',
					})
				})
		},
		deleteMedia() {
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/media/delete`,
				data: {
					name: this.mediaName,
					email: this.locationEmail,
				},
			})
				.then(() => {
					this.getMedia()
					this.deleteConfirmed = false
					this.showDeleteAlert = false
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err?.response?.data?.message,
						color: 'error',
					})
				})
		},
	},
}
</script>

<style scoped>
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
.container {
	height: 100%;
	position: relative;
	text-align: center;
	color: white;
	padding: 0px !important;
}

.delete-btn {
	font-size: 25px !important;
	border: 0px;
}

.delete {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: right;
	font-size: 25px !important;
	padding: 10px;
}

.bottom {
	position: absolute;
	bottom: 0px;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.3) 70%,
		rgba(0, 0, 0, 0.6) 100%
	);
	color: #f1f1f1;
	width: 100%;
	padding: 2px;
	text-align: center;
}
</style>
