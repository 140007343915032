<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="media-drawer"
	>
		<div class="d-flex align-center header pt-4 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="closeDrawer()"
			></span>
			<h1 class="crud-title text-center">Add Media</h1>
		</div>
		<v-tabs v-model="selectedTab" color="deep-purple accent-4" right>
			<v-tab>Photo</v-tab>

			<v-tab-item>
				<div class="pa-4">
					<FileUploader
						:initFiles="[]"
						@errorOccured="dropzoneError"
						:purpose="`GMB`"
						:maxFiles="1"
						@changed="fileUploaderChanged"
					/>
					<v-alert v-if="error.value" dense type="error">
						{{ error.message }}
					</v-alert>
					<ul style="list-style-type: square">
						<li>
							Photos must be in one of these formats: JPG, PNG,
							TIFF, BMP.
						</li>
						<li>
							Each photo must be smaller than 5MB. We recommend
							adding photos that are larger than 720 x 720 pixels
							and smaller than 3000 x 3000 pixels.
						</li>
						<li>
							Each location can have no more than 25 additional
							photos.
						</li>
						<li>
							The image should represent reality. If your photos
							don't fit these standards, we may not be able to
							display them on Google products.
						</li>
					</ul>
					<v-select
						:items="category"
						label="Select Category (optional)"
						v-model="photo.selectedCategory"
					></v-select>
					<v-btn
						@click="saveMedia()"
						:disabled="saving"
						:loading="saving"
						depressed
						class="save"
						>Save</v-btn
					>
				</div>
			</v-tab-item>
			<!-- <v-tab>Video</v-tab> -->
			<v-tab-item>
				<div class="pa-4">
					<v-text-field
						label="Enter Video URL"
						v-model="video.URL"
						outlined
					></v-text-field>
					<v-alert v-if="error.value" dense type="error">
						{{ error.message }}
					</v-alert>
					<v-btn
						@click="saveMedia()"
						:loading="saving"
						:disabled="saving"
						depressed
						class="save"
						>Save</v-btn
					>
				</div>
			</v-tab-item>
		</v-tabs>
	</v-navigation-drawer>
</template>
<script>
import FileUploader from '@/components/FileUploader'

export default {
	components: {
		FileUploader,
	},
	data() {
		return {
			video: {
				URL: null,
			},
			photo: {
				URL: null,
				selectedCategory: null,
			},
			drawer: true,
			category: [
				'COVER',
				'PROFILE',
				'LOGO',
				'EXTERIOR',
				'INTERIOR',
				'ADDITIONAL',
			],
			selectedTab: 0,
			saving: false,
			error: {
				value: false,
				message: null,
			},
		}
	},
	watch: {
		photoURL() {
			this.error.value = false
			this.error.message = null
		},
		videoURL() {
			this.error.value = false
			this.error.message = null
		},
	},
	computed: {
		photoURL() {
			return this.photo.URL
		},
		videoURL() {
			return this.video.URL
		},
		locationName() {
			return this.$cookies.get('gmb-selected-location')
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	methods: {
		async fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.photo.URL = data.publicURL
			} else {
				this.photo.URL = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		closeDrawer() {
			this.$emit('close-drawer')
		},
		errorOccured() {
			this.error.message = this.selectedTab
				? 'Please enter video url'
				: 'Photo not uploaded'
			this.error.value = true
		},
		saveMedia() {
			this.saving = true
			const projectId = this.$store.state.app.projectId
			let payload = {}
			if (!this.selectedTab) {
				payload = {
					name: this.locationName,
					mediaFormat: 'PHOTO',
					sourceUrl: this.photo.URL
						? this.photo.URL
						: this.errorOccured(),
					email: this.locationEmail,
				}
				if (this.photo.selectedCategory) {
					payload.locationAssociationCategory =
						this.photo.selectedCategory
				}
			} else {
				payload = {
					name: this.locationName,
					mediaFormat: 'VIDEO',
					sourceUrl: this.video.URL
						? this.video.URL
						: this.errorOccured(),
					email: this.locationEmail,
				}
			}
			if (!this.error.value) {
				this.axios({
					method: 'post',
					url: `/${projectId}/gmb/media/create`,
					data: payload,
				})
					.then((res) => {
						if (res.status === 200 && res.data.success) {
							this.saving = false
							this.closeDrawer()
						}
						if (res.status === 200 && !res.data.success) {
							this.saving = false
							this.error.value = true
							this.error.message = res.data.message
						}
					})
					.catch((err) => {
						this.$snackbar.notify({
							message: err?.response?.data?.message,
							color: 'error',
						})
					})
			}
		},
	},
}
</script>
<style scoped>
.media-drawer {
	width: 50% !important;
	height: 100% !important;
	overflow-y: auto;
}
.media-drawer .header {
	border-bottom: 1px solid #eee;
}
.media-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.media-drawer .crud-title {
	width: 100%;
}
.media-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.errors {
	color: red;
	font-size: 13px;
}
.footer {
	background-color: var(--blue);
	height: 50px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	float: right;
}
</style>
